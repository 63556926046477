
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import SuiteHeader from '~/components/SuiteHeader/SuiteHeader.vue'
import SubHeader from './SubHeader'
import NotAuthHeader from './NotAuthHeader'
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name : 'View',
  components:{
    SuiteHeader,
    SubHeader,
    NotAuthHeader,
  },
  data(){
    return {
      modal: false
    }
  },
  computed:{
    ...mapGetters([
      'isAuthenticated',
      'forceHideHeader'
    ]),
  },
  methods:{
    showHeader(){
      return  !this.forceHideHeader && this.$router.currentRoute.value.name != 'testpreview' && this.isAuthenticated && this.$router.currentRoute.value.name != '2fa'
    },
    showSubHeader(){
      return this.showHeader() && this.$router.currentRoute.value.name != 'watmonitor' && this.$router.currentRoute.value.name != 'watmonitornew' && this.$router.currentRoute.value.name != 'watmonitorV3'
    },
  },
})
